document.addEventListener("turbo:load", function() {

  $('.ui.checkbox').checkbox();

  $('.ui.dropdown.noselection').dropdown({
    placeholder: false,
    action: "hide",
    onHide : function() {
      $(this).dropdown('clear');
    },
  });

  $('.ui.dropdown.placeholder').dropdown({
    placeholder: false
  })

  $('.ui.dropdown').not(".noselection").not(".custom").dropdown()

  $('.message .close').on('click', function() {
    $(this).closest('.message').transition('fade')
  })

  $('.field_with_errors').each(function() {
    $(this).parent().append($(this).children().detach()).addClass('error')
    return $(this).detach()
  })

})
