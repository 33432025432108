import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ["link"] 
  static values = {
    portalId: String,
    assetUuid: String
  }

  connect() {
    get(`/portals/${this.portalIdValue}/asset/${this.assetUuidValue}/s3_list`, {
      responseKind: 'turbo-stream'
    })
    $('.ui.dropdown').dropdown({
      action: 'select'
    })
  }

  async presigned_s3_url(event) {
    const s3_key = event.target.value
    const request = await get(`/portals/${this.portalIdValue}/asset/${this.assetUuidValue}/s3_presigned_url`, {
      query: {
        s3_key: s3_key
      },
      responseKind: 'json'
    })
    if (request.ok) {
      const url = await request.text
      this.linkTarget.href = url
      this.linkTarget.target = '_blank'
      this.linkTarget.click()
      
      this.dispatch("usage", { detail: { 
        type: 'download_asset', 
        link: this.linkTarget.href,
        asset_uuid: this.assetUuidValue,
        portal_id: this.portalIdValue
      }})
    }
  }
}
