const FPS_FRACTION = {
  0      :    25.0/1.0,  //         - Fallback
  null   :    25.0/1.0,  // 0.0/1.0 - Fallback
  10     :    10.0/1.0,
  11.988 : 12000.0/1001.0,
  15     :   15.0 / 1.0,
  23.976 :    24.0/1.0,
  24     :    24.0/1.0,
  25     :    25.0/1.0,
  29.97  : 30000.0/1001.0,
  30     :    30.0/1.0,
  47.95  : 48000.0/1001.0,
  48     :    48.0/1.0,
  50     :    50.0/1.0,
  59.94  : 60000.0/1001.0,
  60     :    60.0/1.0,
  72     :    72.0/1.0,
  96     :    96.0/1.0,
  100    :   100.0/1.0,
  120    :   120.0/1.0,
}

const SUPPORTED_FPS = Object.keys(FPS_FRACTION)
const TC_FallbackFramerate = 25

function is_negative(number) {
  if (typeof(number) !== 'number') { return false }
  if ( number > 0 ) { return false }
  if (number < 0) { return true }
  if ( 1.0 / number === Number.POSITIVE_INFINITY ) { return false }
  return true
}
const mod = (x, n) => (x % n + n) % n // FIX javscript broken modulo for negative numbers -.- TODO: check other % occurrences


class Timecode {
  constructor(totalframes, framerate, timecode_format, rollover = false, valid = true) {
    this.totalframes = totalframes
    this.framerate = Timecode.check_framerate(framerate)
    this.tc_format = timecode_format == "DF" ? "DF" : "NDF" // Default NDF Fallback
    this.rollover = rollover ? true : false
    this.valid = valid
    this.tc_sep = ':'
    this.tc_drop_sep = ':'
  }

  invalid() {
    return !this.valid
  }
  
  static check_framerate(framerate) {
    if (framerate === false) { return false }
    if (!(typeof(framerate) === 'string' || typeof(framerate) === 'number' ) || framerate === 'tbc' || framerate === '' || framerate === 0) {
      framerate = TC_FallbackFramerate
    }

    if (typeof(framerate) === 'string') {
      framerate = parseFloat(framerate)
    }
    let rounded_framerate = +(framerate).toFixed(3)
    if (!SUPPORTED_FPS.includes(`${rounded_framerate}`)) {
      return false
    }
    return rounded_framerate
  }
  
  static invalid_timecode(info) {
    let itc = new Timecode(null, null, null, false, false)
    if (!info) { return itc }
    itc.info = info
    return itc
  }
  
  static format_timecode_parameter(string, framerate) {
    if ((typeof framerate === 'undefined') || (typeof string === 'undefined')) { return string }
    let fps = Timecode.check_framerate(framerate)
    if (fps === false) { return string }

    let str = string.split('@')[0]
    if (str === '') { str == '00000000' }
    if (str.includes(':')) { str = str.split(/;|:/).map(x => x.padStart(2, '0')).join('') }
    return `${str.padStart(8, '0').match(/.{1,2}/g).join(':')}@${fps}`
  }

  pad (n, width, z) {
    z = z || "0";
    n = n + "";
    if (n.length >= width) { return n; } else { return new Array((width - n.length) + 1).join(z) + n; }
  }
  
  static framerate_could_be_dropframe(fps) {
    let framerate = Timecode.check_framerate(fps)
    return [29.97, 59.94, 30, 60].includes(framerate)
  }
  
  static valid_timecode_hmsf(h, m, s, f, framerate) {
    let fps = Timecode.check_framerate(framerate)
    if (fps === false) { return false }
    if ([h,m,s,f].filter(x => typeof(x) !== 'number').length != 0) { return false }
    var negative = is_negative(h)
    if (!(Math.abs(h) <= 23)) { return false }
    if (!(Math.abs(m) <= 59)) { return false }
    if (!(Math.abs(s) <= 59)) { return false }
    if (!(Math.abs(f) <= parseInt(Math.round(fps), 10))) { return false }
    
    if (h == 0 && m == 0 && s == 0 && f == 0 && negative) {
      negative = false
      h = Math.abs(h)
      m = Math.abs(m)
      s = Math.abs(s)
      f = Math.abs(f)
    }
    
    if (negative) {
      if (!is_negative(m)) { m *= -1 }
      if (!is_negative(s)) { s *= -1 }
      if (!is_negative(f)) { f *= -1 }
    }
    return {h: h, m: m, s: s, f: f}
  }
  
  static valid_timecode_string(tc_string, framerate) {
    if (typeof(tc_string) !== "string" || tc_string == "") { return false } 
    //if (!(typeof(framerate) === "number" || typeof(framerate) === 'string' )) { return false }

    framerate = Timecode.check_framerate(framerate)
    if (framerate === false ) { return false }
    let tc = tc_string.split(/[:;]/)
    if (tc.length != 4) { return false }
    if (!(tc[0].match(/[^0-9-]/) == null && (tc[0].length == 2 || tc[0].length == 3))) { return false }
    // TODO: check if - comes first
    //    string.charAt(0) == '-'
    if (!(tc[1].match(/[^0-9]/) == null && tc[1].length == 2 )) { return false }
    if (!(tc[2].match(/[^0-9]/) == null && tc[2].length == 2 )) { return false }
    if (!(tc[3].match(/[^0-9]/) == null && tc[3].length == 2 )) { return false }
    
    var hmsf = tc.map(x => parseInt(x, 10))
    return Timecode.valid_timecode_hmsf(hmsf[0], hmsf[1], hmsf[2], hmsf[3], framerate)
  }
  
  hmsf_to_frames(hours, minutes, seconds, frames, framerate, timecode_format) {
    timecode_format = timecode_format == "DF" ? "DF" : "NDF" // Default NDF Fallback
    framerate = Timecode.check_framerate(framerate)
    let fps = FPS_FRACTION[framerate].round
    var drop_frames = 0
    if ([29.97, 59.94].includes(framerate) && timecode_format == "DF") {
      drop_frames = (hours * (2 * 9 * 6)) + ((minutes / 10) * (2 * 9)) + ((minutes % 10) * 2)
      if (framerate == 59.94) { drop_frames *= 2 }
    }
    return (hours * 3600 * fps) + (minutes * 60 * fps) + (seconds * fps) + frames - drop_frames
  }
  
  static string_to_hmfs(string, framerate) {
    return Timecode.valid_timecode_string(string, framerate)
  }
  
  static from_string(string, framerate, timecode_format, rollover = false) {
    if ((typeof(timecode_format) !== 'string' || timecode_format == '' ) && typeof(string) === 'string') { 
      timecode_format = string.includes(';') ? 'DF' : 'NDF' // defaults to ndf
    }
    timecode_format = timecode_format == "DF" ? "DF" : "NDF" // Default NDF Fallback
    if (!timecode_format) { timecode_format = 'NDF' }   
    framerate = Timecode.check_framerate(framerate)
    let hmsf = Timecode.string_to_hmfs(string, framerate)
    if (hmsf == false || Timecode.check_framerate(framerate) == null || !(['NDF', 'DF'].includes(timecode_format))) {
      return Timecode.invalid_timecode({from_string: string, framerate: framerate, timecode_format: timecode_format, rollover: rollover})
    }
    let h = hmsf.h
    let m = hmsf.m
    let s = hmsf.s
    let f = hmsf.f
    return Timecode.from_hmsf(h, m, s, f, framerate, timecode_format, rollover)
  }
  toString() {
    if (this.invalid()) { return null }
    let hmsf = this.to_hmsf()
    let h = hmsf.h
    let m = hmsf.m
    let s = hmsf.s
    let f = hmsf.f
    let hours     = h <= 9999 ? Math.abs(Math.round(h)) : 0
    let minutes   = m <=   99 ? Math.abs(Math.round(m)) : 0
    let seconds   = s <=   99 ? Math.abs(Math.round(s)) : 0
    let tc_frames = f <=  999 ? Math.abs(Math.round(f)) : 0
    
    var tc_string = `${this.pad(parseInt(hours), 2, 0)}${this.tc_sep}${this.pad(parseInt(minutes), 2, 0)}${this.tc_sep}${this.pad(parseInt(seconds), 2, 0)}${this.tc_format == 'DF' ? this.tc_drop_sep : this.tc_sep}${this.pad(parseInt(tc_frames), 2, 0)}`
    if (this.totalframes < 0 && !this.rollover) { tc_string = "-".concat(tc_string) }
    return tc_string
  }
  
  static from_hmsf(hours, minutes, seconds, tc_frames, framerate, timecode_format, rollover = false) {
    timecode_format = timecode_format == "DF" ? "DF" : "NDF" // Default NDF Fallback
    let tc_hmsf = Timecode.valid_timecode_hmsf(hours, minutes, seconds, tc_frames, framerate)
    if (tc_hmsf == false || Timecode.check_framerate(framerate) == null || !(['NDF', 'DF'].includes(timecode_format))) {
      return Timecode.invalid_timecode({hours: hours, minutes: minutes, seconds: seconds, frames: tc_frames, framerate: framerate, timecode_format: timecode_format, rollover: rollover})
    }
    framerate = Timecode.check_framerate(framerate)
    let h = tc_hmsf.h
    let m = tc_hmsf.m
    let s = tc_hmsf.s
    let f = tc_hmsf.f
    
    var timecode = new Timecode(0, framerate, timecode_format, rollover)
    let fps = Math.round(FPS_FRACTION[timecode.framerate])
    
    var drop_frames = 0
    if ([29.97, 59.94].includes(timecode.framerate) && timecode_format == "DF") {
      drop_frames = (h * (2 * 9 * 6)) + (parseInt(m / 10, 10) * (2 * 9)) + ((m % 10) * 2)
      if (framerate == 59.94) { drop_frames *= 2 }
    }
    timecode.totalframes = (h * 3600 * fps) + (m * 60 * fps) + (s * fps) + f - drop_frames
    return timecode
  }
  to_hmsf() {
    if (this.invalid()) { return null }
    var totalframes = this.totalframes
    let fps = Math.round(FPS_FRACTION[this.framerate])
    var frames_in_24_hours = 0
    if ([29.97, 59.94].includes(this.framerate) && this.tc_format == "DF") {
      if (this.rollover) {
        frames_in_24_hours = (((((fps*60))*10) - (2*9)) * 6 * 24)
        totalframes = mod(totalframes, frames_in_24_hours)
      }
      totalframes = Math.abs(totalframes)
      
      let frames_per_minute = (fps * 60) - 2

      let frames_in_10_minutes = (frames_per_minute * 10) + 2
      let chunks_of_10_minutes = parseInt(totalframes / frames_in_10_minutes, 10)
      let chunks_of_1_minutes  = parseInt(((totalframes % frames_in_10_minutes) - 2) / frames_per_minute, 10) //# -2 for the first tc_frames 00 and 01
      let ten_minute_drops = 2 * 9 * chunks_of_10_minutes
      let one_minute_drops = chunks_of_1_minutes < 0 ? 0 : 2 * chunks_of_1_minutes
      var drop_frames = ten_minute_drops + one_minute_drops

      if (this.framerate == 59.94 && this.tc_format == "DF") { drop_frames *= 2 }

      totalframes += drop_frames
    } else if (this.rollover) {
      frames_in_24_hours = (((fps*60)*10) * 6 * 24)
      totalframes = mod(totalframes, frames_in_24_hours)
    }
    totalframes = Math.abs(totalframes)
    let tc_frames = totalframes % fps
    let seconds = parseInt(totalframes / fps, 10) % 60
    let minutes = parseInt(parseInt(totalframes / fps, 10) / 60, 10) % 60
    let hours = parseInt(parseInt(parseInt(totalframes / fps, 10) / 60, 10) / 60, 10)
    return {h: hours, m: minutes, s: seconds, f: tc_frames}
  }

  static from_runtime_seconds(s, framerate, timecode_format = 'NDF', rollover = false) {
    timecode_format = timecode_format == "DF" ? "DF" : "NDF" // Default NDF Fallback
    if (typeof(s) !== 'number' || Timecode.check_framerate(framerate) == null || !(['NDF', 'DF'].includes(timecode_format))) {
      return Timecode.invalid_timecode({seconds: s, framerate: framerate, timecode_format: timecode_format, rollover: rollover})
    }
    framerate = Timecode.check_framerate(framerate)
    if (framerate == 23.976) { s *= 0.999000999 }
    let tc_frames = s * FPS_FRACTION[framerate]
    return new Timecode(tc_frames, framerate, timecode_format, rollover)
  }  
  to_runtime_seconds() {
    if (this.invalid()) { return null }
    
    var seconds = parseFloat(this.totalframes) / FPS_FRACTION[this.framerate]
    if (this.framerate == 23.976) { seconds /= 0.999000999 }
    return seconds
  }

  static from_msecs(msecs, framerate, timecode_format = 'NDF', rollover = false) {
    timecode_format = timecode_format == "DF" ? "DF" : "NDF" // Default NDF Fallback
    if (typeof(msecs) !== 'number') {
      return Timecode.invalid_timecode({msecs: msecs, framerate: framerate, timecode_format: timecode_format, rollover: rollover})
    }
    return Timecode.from_runtime_seconds(msecs / 1000, framerate, timecode_format, rollover)
  }
  
  to_msecs() {
    if (this.invalid()) { return null }
    return this.to_runtime_seconds() * 1000
  }
  
//static from_tcms(tcms, framerate, timecode_format) {
//  // TODO: implement
//}
//
//to_tcms() {
//  // TODO: implement
//}

  // pad (n, width, z) {
  //   z = z || "0";
  //   n = n + "";
  //   if (n.length >= width) { return n; } else { return new Array((width - n.length) + 1).join(z) + n; }
  // }

  static add_timecodes(timecode_start, timecode_end, framerate, timecode_format = 'NDF', rollover = false) {
    if (timecode_start === "" || timecode_end === "") { return }
    
    let tc_a = Timecode.from_string(timecode_start, framerate, timecode_format, rollover)
    let tc_b = Timecode.from_string(timecode_end,   framerate, timecode_format, rollover)
    
    let tc_r = new Timecode(tc_a.totalframes + tc_b.totalframes, framerate, timecode_format, rollover)
    return tc_r.toString()
  }

  static subtract_timecodes(timecode_start, timecode_end, framerate, timecode_format = 'NDF', rollover = false) {
    if (timecode_start === "" || timecode_end === "") { return }
    
    let tc_a = Timecode.from_string(timecode_start, framerate, timecode_format, rollover)
    let tc_b = Timecode.from_string(timecode_end,   framerate, timecode_format, rollover)
    
    let tc_r = new Timecode(tc_a.totalframes - tc_b.totalframes, framerate, timecode_format, rollover)
    return tc_r.toString()
  }
  
  add(timecode_b) {
    if (typeof(timecode_b) !== 'object' || (this.invalid() || timecode_b.invalid())) {
      return Timecode.invalid_timecode({error: 'addition of invalid timecodes', timecodes: [this, timecode_b], location: "TODO: find way to include caller location"})
    } else if (this.framerate != timecode_b.framerate || this.tc_format != timecode_b.tc_format) {
      return Timecode.invalid_timecode({error: 'addition with invalid timecode framerate or timecode format', timecodes: [this, timecode_b], location: "TODO: find way to include caller location"})
    } else {
      return new Timecode(this.totalframes + timecode_b.totalframes, this.framerate, this.tc_format, this.rollover)
    }
  }
  
  subtract(timecode_b) {
    if (typeof(timecode_b) !== 'object' || (this.invalid() || timecode_b.invalid())) {
      return Timecode.invalid_timecode({error: 'subtraction of invalid timecodes', timecodes: [this, timecode_b], location: "TODO: find way to include caller location"})
    } else if (this.framerate != timecode_b.framerate || this.tc_format != timecode_b.tc_format) {
      return Timecode.invalid_timecode({error: 'subtraction with invalid timecode framerate or timecode format', timecodes: [this, timecode_b], location: "TODO: find way to include caller location"})
    } else {
      return new Timecode(this.totalframes - timecode_b.totalframes, this.framerate, this.tc_format, this.rollover)
    }
  }
  
  multiply(n) {
    if (typeof(n) !== 'number' && this.valid) {
      return Timecode.invalid_timecode({error: "multiplication with invalid scalar", timecodes: [this]})
    } else if (this.invalid()) { return this }
    return new Timecode(this.totalframes *= n, this.framerate, this.tc_format, this.rollover)
  }
  
  // unknown usage
  get_framerate() {
    let framerate = this.framerate;
    if (framerate === 23.976) { framerate = 24 }
    if (framerate === 29.97)  { framerate = 30 }
    if (framerate === 59.94)  { framerate = 60 }
    if (framerate === "")     { framerate = 24 }
    return framerate;
  }

  correct_time_in_tc(tc) {
    if (tc[3] === undefined) {
      tc[3] = 0;
    }
    if (tc[2] === undefined) {
      tc[2] = 0;
    }
    if (tc[1] === undefined) {
      tc[1] = 0;
    }

    if (parseInt(tc[3]) < 0) {
      tc[3] = parseInt(tc[3]) + parseInt(this.get_framerate());
      tc[2] = parseInt(tc[2]) - 1;
    }

    if (parseInt(tc[2]) < 0) {
      tc[2] = parseInt(tc[2]) + 60;
      tc[1] = parseInt(tc[1]) - 1;
    }
    if (parseInt(tc[1]) < 0) {
      tc[1] = parseInt(tc[1]) + 60;
      tc[0] = parseInt(tc[0]) - 1;
    }
    if (parseInt(tc[0]) < 0) {
      tc[0] = "00";
      tc[1] = parseInt(tc[1]) - 1;
    }
    return this.pad(tc[0], 2, 0) + ":" + this.pad(tc[1], 2, 0) + ":" + this.pad(tc[2], 2, 0) + ":" + this.pad(tc[3], 2, 0);
  }

  static clean_runtime(tc) {
    if (tc.val().length > 0) {
      if (tc.val().length == 11 && tc.val().slice(-2) == "00") {
        tc.val(tc.val().substring(0, 8))
      }
      let clean_value = tc.val().replace(/[^0-9]/g, '');
      clean_value = this.pad(clean_value, 6, 0);
      clean_value = clean_value.replace(/(.{2})(.{2})(.{2})/, '$1:$2:$3');
      tc.val(clean_value);
    }
  }
}

//module.exports = Timecode
window.Timecode = Timecode
