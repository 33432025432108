document.addEventListener("turbo:load", function() {
  document.querySelector('#groups.index') && groups.index()
  document.querySelector('#groups.edit') && groups.edit()
});

const delay = (function() {
  var timer
  timer = 0
  return function(callback, ms) {
    clearTimeout(timer)
    timer = setTimeout(callback, ms)
  }
})()

var groups = {
  index() {

    var delay = (function() {
      let timer = 0;
      return function(callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
      };
    })();

    $('.ui.dropdown.client').dropdown({
      onChange(value, text, $choice) {
        $("#group .name").val(text)
      }
    })

    $("#search_input").focus();

    $("#search_input").keyup(function(event) {
      if (groups.check_search_inputs(event)) {
        return;
      }
      delay((function() {
        groups.perform_search();
        return false;
      }), 1500);
      return false;
    });

    $(".search-field").change(() => groups.perform_search());

    groups.perform_search()
  },

  edit() {
    $("#groups .item[data-tab]").tab()

    delay((function() {
      $(".xyz-in").removeClass("xyz-in")
    }), 3000)

    $('.ui.custom.contact.dropdown').not(".noselection").dropdown({
      onChange(value, text, $choice) {
        $("#portal_media_portal_attributes_contact_id").val(value)
      }
    })

    portals.image_upload()
    portals.preview()
  },

  check_search_inputs(event) {
    if (event.keyCode === 16) {
      return true;
    } else if (event.keyCode === 17) {
      return true;
    } else if (event.keyCode === 18) {
      return true;
    } else if (event.keyCode === 91) {
      return true;
    } else if (event.keyCode === 93) {
      return true;
    } else if (event.keyCode === 37) {
      return true;
    } else if (event.keyCode === 38) {
      return true;
    } else if (event.keyCode === 39) {
      return true;
    } else if (event.keyCode === 40) {
      return true;
    } else {
      return false;
    }
  },
  
  perform_search() {
    $("#search_input").parent().addClass('loading');
    return $.ajax({
      url: "/groups",
      dataType: "script",
      data: groups.collect_search(),
      complete() {
        return $("#search_input").parent().removeClass('loading');
      }
    });
  },

  collect_search() {
    const search_params = {};
    $('.search-field').each(function(index, element) {
      if ($(element).hasClass('dropdown')) {
        element = $(element).children().first();
      }
      const id = $(element).attr("id");
      const value = $(element).val();
      return search_params[id] = value;
    });
    return search_params;
  }
}

window.groups = groups
