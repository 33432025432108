document.addEventListener("confirm", function(e) {
  let link = $(e.target)

  if (link.attr("data-method") == "delete") {
    e.preventDefault()

    $.ajax({
      url: '/general/confirm_modal',
      data: {
        model_name: link.attr('data-app'),
        model_id: link.attr('data-id'),
      }
    }).done(function(result) {

      $("body").append(result)

      const modal = $('.ui.confirm.delete.modal')
      
      $(modal).find('.header').text(`Delete ${link.attr('data-app').replace(/([a-z0-9])([A-Z])/g, '$1 $2')} ${link.attr('data-id')}`)
      $(modal).find('.content p').text(`Do you really want to delete "${link.attr('data-title') || link.attr('data-id')}"?`)
      
      modal.modal({
        onHidden() {
          modal.remove()
        },
        onApprove() {
          return false
        }
      }).modal('show');

    }).fail(function(error) {
      
    })

  }
  
});
