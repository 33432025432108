// Entry point for the build script in your package.json

import './vendor/jquery'

import Rails from "@rails/ujs"
import '@rails/request.js'
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"

import { Application } from "@hotwired/stimulus"
window.Stimulus = Application.start()

import { post } from '@rails/request.js'
window.post = post

import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  Stimulus.register(controller.name.replace("controllers--", ""), controller.module.default)
})

import "./channels/**/*_channel.js"

Rails.start()
ActiveStorage.start()
//Turbo.session.drive = false

import 'fomantic-ui/dist/semantic'

import moment from 'moment'
window.moment = moment

import("chartkick/chart.js")

import "./src/**/*"

import("./video/play")

import("./vendor/fontawesome")
