import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ["search-options"] 
  static values = {
    portalId: String,
  }

  connect() {
    this.search_options()
  }

  async search_options () {
    const response = await get(`/portals/${this.portalIdValue}/search_options`, {
      responseKind: 'turbo-stream'
    })

    if (response.ok) {
      $(".portal-search-options .accordion").accordion("destroy")
      $(".portal-search-options .accordion").accordion({
        animateChildren: false
      })
  
      $("#search-options-button").off('click')
      $("#search-options-button").on('click', function() {
        $(".portal-search-options .accordion").accordion("toggle", 0)
        
        $('.ui.dropdown').dropdown()
        $('.ui.checkbox').checkbox()

        $(".search-field").off('change')
        $(".search-field").on('change', () => portals.perform_search(`/portals/${$("#portal-show").data("portal-id")}/assets`))
      })
    }
  }

}
