document.addEventListener("turbo:load", function() {
  if ($(".users-search").length > 0) {
    users.index()
  }
})

var users = {
  index() {
    var delay = (function() {
      let timer = 0;
      return function(callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
      };
    })();

    $("#search_input").focus();

    $("#search_input").keyup(function(event) {
      if (users.check_search_inputs(event)) {
        return;
      }
      delay((function() {
        users.perform_search()
        return false;
      }), 1500);
      return false;
    })

    users.perform_search()

    $(".search-field").off('change')
    $(".search-field").on('change', () => users.perform_search())

    $(".portal-search-options .accordion").accordion({
      animateChildren: false
    })

    $("#search-options-button").on('click', function() {
      $(".portal-search-options .accordion").accordion("toggle", 0)
    })
  },

  form() {

  },

  collect_search() {
    const search_params = {};
    $('.search-field').each(function(index, element) {
      if ($(element).hasClass('dropdown')) {
        element = $(element).children().first();
      }
      const id = $(element).attr("id");
      const value = $(element).val();
      return search_params[id] = value;
    });
    return search_params;
  },

  check_search_inputs(event) {
    if (event.keyCode === 16) {
      return true;
    } else if (event.keyCode === 17) {
      return true;
    } else if (event.keyCode === 18) {
      return true;
    } else if (event.keyCode === 91) {
      return true;
    } else if (event.keyCode === 93) {
      return true;
    } else if (event.keyCode === 37) {
      return true;
    } else if (event.keyCode === 38) {
      return true;
    } else if (event.keyCode === 39) {
      return true;
    } else if (event.keyCode === 40) {
      return true;
    } else {
      return false;
    }
  },

  perform_search() {
    $("#search_input").parent().addClass('loading');
    return $.ajax({
      url: $("#search_input").data('url'),
      dataType: "script",
      data: users.collect_search(),
      complete() {
        return $("#search_input").parent().removeClass('loading');
      }
    });
  },
}

window.users = users
