
var statistics = {

  get_stats() {

    var params = {
      user_id: $('#stat_user_id').val(),
      portal_id: $('#portal_id').val(),
      date_from: $('#date_from').val(),
      date_to: $('#date_to').val(),
      client_id: $('#group_id').val(),
      role_id: $("#user_role").val(),
      token: $("#single_access_token").val(),
      portal_users: $.map($('#stat_user_id option'), function(val, _i) {
        if ($(val).val() !== "") {
          return {
            id: $(val).val(),
            name: $(val).text()
          }
        }
      })
    }

    $.ajax({
      url: "/mediaportalgetstatisticoverview",
      type: "POST",
      dataType: "json",
      data: params,
      success: function(data, _textStatus, _xhr) {
        $('#total_portal_view_count').text(data['total_portal_view_count']);
        $('#total_play_count').text(data['total_play_count']);
      }
    })

    $.ajax({
      url: "/mediaportalgettotalportalviewchartformonth",
      type: "POST",
      dataType: "json",
      data: params,
      success: function(data, _textStatus, _xhr) {
        return new Chartkick.ColumnChart("month-chart-total-views", data, {
          "colors": ["#3e63dd"]
        })
      }
    })

    $.ajax({
      url: "/mediaportalgettotalplaycountchartformonth",
      type: "POST",
      dataType: "json",
      data: params,
      success: function(data, _textStatus, _xhr) {
        return new Chartkick.ColumnChart("month-chart-total-plays", data, {
          "colors": ["green"]
        })
      }
    })

    $.ajax({
      url: "/mediaportalgettopassetchart",
      type: "POST",
      dataType: "json",
      data: params,
      success: function(data, _textStatus, _xhr) {
        return new Chartkick.BarChart("top-asset-chart", [
          {
            name: "Video starts",
            data: data
          }
        ], {
          "colors": ["orange"]
        })
      }
    })

    $.ajax({
      url: "/mediaportalgettopuserchart",
      type: "POST",
      dataType: "json",
      data: params,
      success: function(data, _textStatus, _xhr) {
        return new Chartkick.BarChart("top-user-chart", [
          {
            name: "Video starts",
            data: data
          }
        ], {
          "colors": ["#913bac"]
        })
      }
    })

  }
}

window.statistics = statistics
