import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

const controller = new AbortController()

export default class extends Controller {
  static targets = ["portalUsers", "dateFrom", "dateTo"]
  
  static values = {
    portalId: Number
  }

  connect() {
    calendar.datepicker_range('#statistics', 'startdate')
    calendar.datepicker_range('#statistics', 'enddate', { ...calendar.default_options(), ...{ 'maxDate': new Date() } })
    this.fetch_statistics()
  }

  async fetch_statistics(_event) {
    controller.signal
    let params = this.collect_params()
    controller.abort()
    this.loading_animation()
    this.views_per_day_chart(params)
    this.plays_per_day_chart(params)
    this.top_user_chart(params)
    this.top_asset_chart(params)
  }

  async views_per_day_chart(params) {
    const response = await get(`/portals/${this.portalIdValue}/views_per_day_chart`, params).catch()
      
    if (response.ok) {
      const data = await response.json
      const views_per_day_chart_view = new Chartkick.ColumnChart("views-per-day-chart", data, {
        empty: "No available data"
      })
      views_per_day_chart_view.getElement().parentNode.classList.remove('loading')
    }
  }

  async plays_per_day_chart(params) {
    const response = await get(`/portals/${this.portalIdValue}/plays_per_day_chart`, params).catch()
      
    if (response.ok) {
      const data = await response.json
      const plays_per_day_chart_view = new Chartkick.ColumnChart("plays-per-day-chart", data, {
        empty: "No available data"
      })
      plays_per_day_chart_view.getElement().parentNode.classList.remove('loading')
    }
  }

  async top_user_chart(params) {
    const response = await get(`/portals/${this.portalIdValue}/top_user_chart`, params).catch()

    if (response.ok) {
      const data = await response.json
      const top_user_chart_view = new Chartkick.ColumnChart("top-user-chart", data, {
        empty: "No available data"
      })
      top_user_chart_view.getElement().parentNode.classList.remove('loading')
    }
  }

  async top_asset_chart(params) {
    const response = await get(`/portals/${this.portalIdValue}/top_asset_chart`, params).catch()
    if (response.ok) {
      const data = await response.json
      const top_asset_chart_view = new Chartkick.ColumnChart("top-asset-chart", data, {
        empty: "No available data"
      })
      top_asset_chart_view.getElement().parentNode.classList.remove('loading')
    }
  }
  
  collect_params() {
    return { 
      query: {
        date_from: this.dateFromTarget.value,
        date_to: this.dateToTarget.value,
        portal_users: [...this.portalUsersTarget.options].filter(option => option.selected).map(option => option.value)
      }
    }
  }

  loading_animation() {
    document.querySelectorAll('.placeholder.segment').forEach(element => {
      element.classList.add('loading')
    })
  }
}
