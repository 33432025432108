import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

export default class extends Controller {

  send(event) {
    let body;

    if (event.detail) {
      body = event.detail
    } else {
      body = this.parseEvent(event)
    }

    post('/mediaportaladdusageentry', {
      body: body
    })
  }

  parseEvent(event) {
    let body
    const portalAsset = document.getElementById('portal-asset')

    switch (event.params.eventType) {
      case 'player':
        body = {
          type: 'player_event',
          asset_id: portalAsset.dataset.assetId,
          portal_id: portalAsset.dataset.portalId,
          player_event: {
            event_type: event.type,
            event_time: window.player.currentTime()
          }
        }
        break;
      default:
        break;
    }

    return body
  }

}
