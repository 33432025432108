$.fn.dropdown.settings.forceSelection = false
$.fn.dropdown.settings.saveRemoteData = false
$.fn.search.settings.cache = false

import "../video/play"

const delay = (function() {
  var timer
  timer = 0
  return function(callback, ms) {
    clearTimeout(timer)
    timer = setTimeout(callback, ms)
  }
})()

document.addEventListener("turbo:load", function() {

  if ($("#portals.index").length > 0) {
    portals.index()
  }

  if ($("#portal-show").length > 0) {
    portals.show()
  }

  if ($("#portal-asset").length > 0) {
    portals.asset()
  }

  if ($("#portals.edit").length > 0) {
    portals.edit()
  }

})

var portals = {

  index() {
    if ($(".portals-search").length > 0) {
      $("#search_input").trigger('focus')
  
      $("#search_input").on('keyup', function(event) {
        if (portals.check_search_inputs(event)) {
          return
        }
        delay((function() {
          portals.perform_search("/portals")
          return false
        }), 1500)
        return false
      })
  
      $(".search-field").off('change')
      $(".search-field").on('change', () => portals.perform_search("/portals"))
  
      portals.perform_search("/portals")

      $(".portal-search-options .accordion").accordion("destroy")
      $(".portal-search-options .accordion").accordion({
        animateChildren: false
      })

      $("#search-options-button").off('click')
      $("#search-options-button").on('click', function() {
        $(".portal-search-options .accordion").accordion("toggle", 0)
      })
    } 
  },

  index_body() {
    var isFirefox = (navigator.userAgent.indexOf('Firefox') !== -1);
    if (isFirefox) {
      $(".portal.xyz-in").attr("xyz", "fade duration-3 ease-out")
    }

    $(".portal-bg").off("click")
    $(".portal-bg").on("click", function() {
      $(".portals-search").css("animation-delay", "0ms")
      $(".xyz-in").removeClass("xyz-in").addClass("xyz-out")

      //$(".james.bg").parent().prepend("<div class='portals bg'></div>")

      const background = $(this).data("background-url")
      const portal_style = "<style type='text/css'> .portals.bg { background-image: url('" + background + "'); }</style>"

      $(".portals.bg").append(portal_style)
      $(".portals.bg").css("opacity", "1.0")
      
      const link = $(this).find(".portal-link").attr("href")
      delay((function() {
        Turbo.visit(link)
      }), 1000)
    })

    $(".buttons-box .ui.button").off("click")
    $(".buttons-box .ui.button").on("click", function() {
      Turbo.visit($(this).attr("href"))
    })

    $(".buttons-box .ui.button")
      .on("mouseenter", function() {
        $(".portal-bg").off("click")
      })
      .on("mouseleave", function() {
        $(".portal-bg").on("click", function() {
          Turbo.visit($(this).find(".portal-link").attr("href"))
        })
      })

    $('.portal')
      .on("mouseenter", function() {
        $(this).find(".action.buttons").show()
        $(this).find(".portal-categories").addClass("shift")
      })
      .on("mouseleave", function() {
        $(this).find(".action.buttons").hide()
        $(this).find(".portal-categories").removeClass("shift")
      })
  },

  show() {
    if ($(".portal-search").length > 0) {
      $("#search_input").trigger('focus')
  
      $("#search_input").on('keyup', function(event) {
        if (portals.check_search_inputs(event)) {
          return
        }
        delay((function() {
          portals.perform_search(`/portals/${$("#portal-show").data("portal-id")}/assets`)
          return false
        }), 1500)
        return false
      })

      $(".search-field").off('change')
      $(".search-field").on('change', () => portals.perform_search(`/portals/${$("#portal-show").data("portal-id")}/assets`))
  
      portals.perform_search(`/portals/${$("#portal-show").data("portal-id")}/assets`)
      $(".portals.bg")?.remove()
    }
  },

  asset() {
    portals.back_button()
    if ($('#player-frame').length > 0) {
      playerTools.insertVideo()
      delay(() => {
        $("#player-frame.xyz-in").removeClass("xyz-in")
      }, 1500);
    }
    
    portals.footer_assets()
  },

  edit() {
    $(".assets-and-users.menu .item[data-tab]").tab()

    delay((function() {
      $(".xyz-in").removeClass("xyz-in")
    }), 3000)

    $('.ui.custom.contact.dropdown').not(".noselection").dropdown({
      onChange(value, text, $choice) {
        $("#portal_media_portal_attributes_contact_id").val(value)
      }
    })

    portals.image_upload()
    portals.preview()

    $("#add-users").on('click', function() {
      $("#add-users").addClass("loading")
      portals.add_users_modal()
    })

    portals.users()

    $("#add-assets").on('click', function() {
      $("#add-assets").addClass("loading")
      portals.add_assets_modal()
    })
  },

  preview() {
    $("#portal_media_portal_attributes_title").on('keyup', function(event) {
      $(".portal-text .portal-title").text($("#portal_media_portal_attributes_title").val())
    })

    $("#portal_media_portal_attributes_description").on('keyup', function(event) {
      $(".portal-text .portal-description").text($("#portal_media_portal_attributes_description").val())
    })
  },

  image_upload() {
    let background, email_brand, logo, logo_box, portal_bg

    $(".remove-logo-img").on('click', function() {
      $("#portal_remove_logo").trigger('click')
      $("#group_remove_logo").trigger('click')
      $(".remove-logo-img").hide()
      return $(".logo-img").attr('src', $('#default_logo').val())
    })
    $(".remove-background-img").on('click', function() {
      $("#portal_remove_background").trigger('click')
      $("#group_remove_background").trigger('click')
      $(".remove-background-img").hide()
      return $(".background-img").attr('src', $('#default_background').val())
    })
    $(".remove-email-brand-img").on('click', function() {
      $("#group_remove_email_brand").trigger('click')
      $(".remove-email-brand-img").hide()
      $(".email-brand-img").attr('src', $('#default_email_brand').val())
    })
    $(".logo-img").on('click', function() {
      $(".logo-file").trigger('click')
    })
    $(".background-img").on('click', function() {
      $(".background-file").trigger('click')
    })
    $(".email-brand-img").on('click', function() {
      $(".email-brand-file").trigger('click')
    })
    logo = $(".logo-img")
    logo_box = $(".logo-box img")
    $(".logo-file").on('change', function(event) {
      var file, input, reader
      input = $(event.currentTarget)
      file = input[0].files[0]
      reader = new FileReader()
      reader.onload = function(e) {
        var image_base64
        image_base64 = e.target.result
        logo.attr("src", image_base64)
        logo_box.attr("src", image_base64)
        portals.set_logo_colors()
      }
      reader.readAsDataURL(file)
    })
    background = $(".background-img")
    portal_bg = $(".portal-bg")
    $(".background-file").on('change', function(event) {
      var file, input, reader
      input = $(event.currentTarget)
      file = input[0].files[0]
      reader = new FileReader()
      reader.onload = function(e) {
        var image_base64
        image_base64 = e.target.result
        background.attr("src", image_base64)
        portal_bg.attr("style", `background: url("${image_base64}"); background-size: cover;`)
      }
      reader.readAsDataURL(file)
    })
    email_brand = $(".email-brand-img")
    $(".email-brand-file").on('change', function(event) {
      var file, input, reader
      input = $(event.currentTarget)
      file = input[0].files[0]
      reader = new FileReader()
      reader.onload = function(e) {
        var image_base64
        image_base64 = e.target.result
        email_brand.attr("src", image_base64)
      }
      reader.readAsDataURL(file)
    })
  },

  users() {
    $.ajax({
      url: `/portals/${$("#portals").data('model-id')}/users`,
      success(data, _textStatus, _xhr) {
        $("#portal-users").html(data)
        $("#portal-users .remove-user").off("click")
        $("#portal-users .remove-user").on("click", function() {
          portals.remove_user($(this).data("model-id"))
        })
      },
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown)
      }
    })
  },

  users_from_portal() {
    $("#add-from-portal").on("click", function() {
      console.log("add-from-portal - click")
      const portal_id = $("#portals").data('model-id')
      $.ajax({
        url: `/portals/${portal_id}/add_assets_modal`,
        success(data, _textStatus, _xhr) {
          $("body").append(data)
          $("#add-assets").removeClass("loading")
          const modal = $('.add.assets.modal')
          modal.modal({
            allowMultiple: true,
            closable: false,
            onShow() {
              // portals.add_users_list(portal_id)
              // portals.add_users_search(portal_id)
            },
            onApprove: function() {
              //portals.add_users(portal_id)
    
              return false
            },
            onHidden() {
              modal.remove()
            }
          }).modal("show")
        },
        error(_jqXHR, _textStatus, errorThrown) {
          return console.log(errorThrown)
        }
      })
    })
  },

  add_assets_modal() {
    const portal_id = $("#portals").data('model-id')
    
    $.ajax({
      url: `/portals/${portal_id}/add_assets_modal`,
      success(data, _textStatus, _xhr) {
        $("body").append(data)
        $("#add-assets").removeClass("loading")
        const modal = $('.add.assets.modal')
        modal.modal({
          allowMultiple: false,
          closable: false,
          onShow() {
            // portals.add_users_list(portal_id)
            // portals.add_users_search(portal_id)
          },
          onApprove: function() {
            //portals.add_users(portal_id)
  
            return false
          },
          onHidden() {
            modal.remove()
          }
        }).modal("show")
      },
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown)
      }
    })
  },

  add_users_modal() {
    const portal_id = $("#portals").data('model-id')
    
    $.ajax({
      url: `/portals/${portal_id}/add_user_modal`,
      success(data, _textStatus, _xhr) {
        $("body").append(data)
        $("#add-users").removeClass("loading")
        const modal = $('.add.user.modal')
        modal.modal({
          allowMultiple: true,
          closable: false,
          onShow() {
            portals.add_users_list(portal_id)
            portals.add_users_search(portal_id)
            portals.users_from_portal()
          },
          onApprove: function() {
            portals.add_users(portal_id)
  
            return false
          },
          onHidden() {
            modal.remove()
          }
        }).modal("show")
      },
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown)
      }
    })
  },

  add_users_list(portal_id) {
    $.ajax({
      url: `/portals/${portal_id}/add_user_list`,
      data: {
        search_input: $(".add.user.modal .search .search-field").val()
      },
      success(data, _textStatus, _xhr) {
        $(".add.user.modal .content #users-list").html(data)
        portals.init_selectors()
        $("#search-count").text($('.add.user.modal .selector').length)
      },
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown)
      }
    })
  },

  add_users_search(portal_id) {
    var delay = (function() {
      let timer = 0;
      return function(callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
      };
    })()

    $("#search_input").focus()
    $("#search_input").keyup(function(event) {
      delay((function() {
        portals.add_users_list(portal_id)
        return false;
      }), 300);
      return false;
    })
  },

  add_users(portal_id) {
    let user_ids = []
    $('.add.user.modal .selector .selected').map((_, item) => {
      const model_id = $(item).parent().data("model-id")
      user_ids.push(model_id)
    })

    $.ajax({
      url: `/portals/${portal_id}/add_users`,
      type: "post",
      data: {
        user_ids: user_ids,
      },
      success(data, _textStatus, _xhr) {
        $('.add.user.modal').modal("hide")
        portals.users()
      },
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown)
      }
    })
  },

  remove_user(user_id) {
    console.log(user_id)
    $.ajax({
      url: `/portals/${$("#portals").data('model-id')}/remove_user`,
      type: "post",
      data: {
        user_id: user_id,
      },
      success(data, _textStatus, _xhr) {
        portals.users()
      },
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown)
      }
    })
  },

  init_selectors() {
    $('.add.user.modal .symbol').hide()
    $('.add.user.modal .selector').show()
    $(".add.user.modal .selector").off('click')
    $(".add.user.modal .selector").on('click', function() {
      if ($(this).find(".selected").length > 0) {
        index_select.unselect(this)
      } else {
        index_select.select(this)
      }
      portals.check_add_user_button()
    })
  },

  check_add_user_button() {
    if ($('.add.user.modal .selector .selected').length > 0) {
      $('#add-users-to-portal').removeClass('disabled')
    } else {
     $('#add-users-to-portal').addClass('disabled')
    }
  },

  portal_assets() {
    $(".pagination .item").on('click', (element) => search.pagination_loader(element))
    delay((function() {
      $(".portal-search.xyz-in").removeClass("xyz-in")
      $(".portal-assets .xyz-in").removeClass("xyz-in")
    }), 1000)
  },

  back_button() {
    $(".portal-back-button").on('click', function() {
      Turbo.visit($(this).data("path"))
    })
  },

  footer_assets() {
    return $.ajax({
      url: `/portals/${$("#portal-asset").data("portal-id")}/footer_assets`,
      dataType: "script",
      data: {
        asset_id: $("#portal-asset").data("asset-id")
      },
      complete() {
        delay((function() {
          $(".assets-slider .xyz-in").removeClass("xyz-in")
        }), 1000)
      },
    })
  },

  set_logo_colors() {
    $(".portal .logo-box").each((i, item) =>
      portals.set_background_color(item)
    )
  },

  set_background_color(item) {
    const color = portals.getcolor($(item).find(".logo")[0])
    $(item)[0].style.backgroundColor = "rgb(" + color.r + ", " + color.g + ", " + color.b + ")"

    portals.getcolor($(item).find(".logo")[0])
  },

  getcolor(imgEl) {
    var blockSize = 5, // only visit every 5 pixels
        defaultRGB = {r:0,g:0,b:0}, // for non-supporting envs
        canvas = document.createElement('canvas'),
        context = canvas.getContext && canvas.getContext('2d'),
        data, width, height,
        i = -4,
        length,
        rgb = {r:0,g:0,b:0},
        count = 0;
        
    if (!context) {
        return defaultRGB;
    }
    
    height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
    width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
    
    context.drawImage(imgEl, 0, 0);
    
    try {
        data = context.getImageData(0, 0, width, height);
    } catch(e) {
        return defaultRGB;
    }
    
    length = data.data.length;
    
    while ( (i += blockSize * 4) < length ) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i+1];
        rgb.b += data.data[i+2];
        break
    }
    
    // ~~ used to floor values
    rgb.r = ~~(rgb.r/count);
    rgb.g = ~~(rgb.g/count);
    rgb.b = ~~(rgb.b/count);
    
    return rgb;
  },

  perform_search(url) {
    $("#search_input").parent().addClass('loading')
    return $.ajax({
      url: url,
      dataType: "script",
      data: portals.collect_search(),
      complete() {
        $("#search_input").parent().removeClass('loading')
        return 
      }
    })
  },

  check_search_inputs(event) {
    if (event.keyCode === 16) {
      return true
    } else if (event.keyCode === 17) {
      return true
    } else if (event.keyCode === 18) {
      return true
    } else if (event.keyCode === 91) {
      return true
    } else if (event.keyCode === 93) {
      return true
    } else if (event.keyCode === 37) {
      return true
    } else if (event.keyCode === 38) {
      return true
    } else if (event.keyCode === 39) {
      return true
    } else if (event.keyCode === 40) {
      return true
    } else {
      return false
    }
  },
  
  collect_search() {
    const search_params = {}
    $('.search-field').each(function(index, element) {
      var id, value
      if ($(element).hasClass('dropdown')) {
        element = $(element).children().first()
      }
      value = $(element).val()
      if ($(element).parent().hasClass('checkbox')) {
        value = $(element).prop("checked").toString()
      }
      id = $(element).attr("id")
      return search_params[id] = value
    })
    return search_params
  },

}

window.portals = portals
