const index_select = {
  select(element) {
    $(element).find("svg").remove()
    $(element).find("i").remove()
    $(element).html('<i class="fas fa-check-circle fa-w-16 fa-2x selected"></i>')
  },

  unselect(element) {
    $(element).find("svg").remove()
    $(element).find("i").remove()
    $(element).html('<i class="far fa-circle fa-w-16 fa-2x unselected"></i>')
  },
};

window.index_select = index_select;
