import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ["count"] 
  static values = {
    portalId: String,
  }

  connect() {
    get(`/portals/${this.portalIdValue}/assets_count`, {
      responseKind: 'turbo-stream'
    })
  }
}
